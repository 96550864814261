.slick-prev:before, .slick-next:before{
    display: none;
}

@media (max-width:768px){
    .slick-slide{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slick-prev, .slick-next{
        z-index: 1;
    }
    .slick-list{
        overflow: hidden;
    }
    .slick-initialized .slick-slide{
        display: flex!important;
    }
}