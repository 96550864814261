:root{
  --proxima-nova-regular: 'Proxima Nova Regular', sans-serif;
  --proxima-nova-extrabold: 'ProximaNova-ExtraBold', sans-serif;
  --proxima-nova-light: 'ProximaNova-Light', sans-serif;
  --proxima-nova-bold: 'Proxima Nova Bold', sans-serif;
  --proxima-nova-altlight: 'Proxima Nova Alt Light', sans-serif;
  --proxima-nova-altbold: 'Proxima Nova Alt Bold', sans-serif;
  --vh:100%;
}

*{
 box-sizing: border-box;
 margin: 0;
 padding: 0;
}
.App{
  font-family: var(--proxima-nova-regular);
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, var(--proxima-nova-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/proximanova_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('./fonts/proximanova_light.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('./fonts/proximanova_bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-ExtraBold';
  src: url('./fonts/proximanova_extrabold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova Alt Light';
  src: url('./fonts/Proxima Nova Alt Light.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova Alt Bold';
  src: url('./fonts/Proxima Nova Alt Bold.otf') format('opentype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width:600px){
  body{
    overflow-x: hidden;
  }
}